.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.chatContainer {
  width: 600px;
  height: 350px;
  border: 5px solid #0091ff;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
}

.chatContainer .messages {
  flex: 80%;
  width: 100%;
  padding-left: 20px;
}

.chatContainer .messageInputs {
  flex: 20%;
  width: 100%;
  display: flex;
  flex-direction: row;

}

.chatContainer .messageInputs input {
  flex: 80%;
  height: calc(100% -5px);
  border: none;
  border-top: 5px solid #0091ff;
  padding-left: 20px;
  font-size: 20px;
}

.chatContainer .messageInputs button {
  flex: 20%;
  height: 100%;
  background-color: #0091ff;
  border: none;
  color: white;
  font-size: 18px;
}

.messageContainer {
  display: flex;
  width: calc(100% - 30px);
}

.messageContainer h1 {
  color: white;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 300;
  font-size: 17px;
}

#You {
  justify-content: flex-start;
}

#Other {
  justify-content: flex-end;
}

#Other .messageIndividual {
  background-color: #5ff064;
  color: black;
}

.messageIndividual {
  width: 200px;
  height: 60px;
  border-radius: 10px;
  display: grid;
  place-items: center;
  background-color: #0091ff;
  opacity: 0.9;
  color: white;
  font-family: Arial, Helvetica, sans-serif;
  margin-right: 10px;
  margin-top: 20px;

}